<template>
  <div>
    <!--begin::details View-->
    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
      <!--begin::Card header-->
      <div class="card-header cursor-pointer">
        <!--begin::Card title-->
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Add Consumer</h3>
        </div>
        <!--end::Card title-->
      </div>
      <!--begin::Card header-->

      <!--begin::Card body-->
      <div class="card-body p-9">

        <div class="row mb-3" v-show="success">
          <div class="col-12">
            <div class="alert alert-success" role="alert">
              Successfully added consumer!
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">First Name</label>
            <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.firstName}" v-model="formData.firstName" placeholder="Enter first name please">
            <span class="text-danger" v-if="errors.value && errors.value.firstName">{{errors.value && errors.value.firstName[0]}}</span>
          </div>
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">Last Name</label>
            <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.lastName}" v-model="formData.lastName" placeholder="Enter last name please">
            <span class="text-danger" v-if="errors.value && errors.value.lastName">{{errors.value && errors.value.lastName[0]}}</span>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12">
            <label class="col-form-label required fw-bold fs-6">Birthday</label>
            <input type="date" class="form-control" :class="{'is-invalid' : errors.value && errors.value.birthday}" v-model="formData.birthday" placeholder="Enter birthday please">
            <span class="text-danger" v-if="errors.value && errors.value.birthday">{{errors.value && errors.value.birthday[0]}}</span>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12">
            <label class="col-form-label required fw-bold fs-6">Address Line 1</label>
            <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.address1}" v-model="formData.address1" placeholder="Enter address please">
            <span class="text-danger" v-if="errors.value && errors.value.address1">{{errors.value && errors.value.address1[0]}}</span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <label class="col-form-label fw-bold fs-6">Address Line 2</label>
            <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.address2}" v-model="formData.address2" placeholder="Enter address please">
            <span class="text-danger" v-if="errors.value && errors.value.address2">{{errors.value && errors.value.address2[0]}}</span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">Town / City</label>
            <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.city}" v-model="formData.city" placeholder="Enter town / city please">
            <span class="text-danger" v-if="errors.value && errors.value.city">{{errors.value && errors.value.city[0]}}</span>
          </div>
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">County</label>
            <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.county}" v-model="formData.county" placeholder="Enter county please">
            <span class="text-danger" v-if="errors.value && errors.value.county">{{errors.value && errors.value.county[0]}}</span>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">Country</label>
            <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.country}" v-model="formData.country" placeholder="Enter country please">
            <span class="text-danger" v-if="errors.value && errors.value.country">{{errors.value && errors.value.country[0]}}</span>
          </div>
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">Postcode</label>
            <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.postcode}" v-model="formData.postcode" placeholder="Enter postode please">
            <span class="text-danger" v-if="errors.value && errors.value.postcode">{{errors.value && errors.value.postcode[0]}}</span>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12">
            <label class="col-form-label required fw-bold fs-6">Email</label>
            <input type="email" class="form-control" :class="{'is-invalid' : errors.value && errors.value.email}" v-model="formData.email" placeholder="Enter email please">
            <span class="text-danger" v-if="errors.value && errors.value.email">{{errors.value && errors.value.email[0]}}</span>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">Mobile</label>
            <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.mobile}" v-model="formData.mobile" placeholder="Enter mobile please">
            <span class="text-danger" v-if="errors.value && errors.value.mobile">{{errors.value && errors.value.mobile[0]}}</span>
          </div>
          <div class="col-12 col-lg-6 mb-3 mb-lg-0">
            <label class="col-form-label fw-bold fs-6">Tel</label>
            <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.telephone}" v-model="formData.telephone" placeholder="Enter telephone please">
            <span class="text-danger" v-if="errors.value && errors.value.telephone">{{errors.value && errors.value.telephone[0]}}</span>
          </div>
        </div>
      </div>
      <!--end::Card body-->
      <div class="card-footer cursor-pointer">
        <!--begin::Card title-->
        <div class="text-end m-0">
          <button
            type="submit"
            ref="submitButton"
            class="btn btn-lg btn-primary fw-bolder me-3 my-2"
            @click="submitForm"
          >
            <span class="indicator-label">
              Save
            </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
        <!--end::Card title-->
      </div>
    </div>

  </div>
</template>
<script lang="ts">
  import { reactive, ref, onMounted } from 'vue'
  import { useStore } from "vuex";
  import { Actions } from "@/store/enums/ConsumerEnums.ts";
  import { useRouter } from "vue-router";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  export default {
    name : 'ConsumerAdd',
    setup(){

      onMounted(() => {
        setCurrentPageBreadcrumbs("Consumers",[
          {title : "Consumers",to : "/consumers/list"},
          {title : "New Consumer",to : "/consumers/add"}
        ]);
      });

      const store = useStore();
      const router = useRouter();

      const submitButton = ref<HTMLElement | null>(null);

      const success = ref(false)

      const errors = reactive({
        value : {}
      });

      const formData = reactive({
        firstName : '',
        lastName : '',
        birthday : '',
        address1 : '',
        address2 : '',
        city : '',
        county : '',
        country : '',
        postode : '',
        email : '',
        mobile : '',
        telephone : ''
      })

      const submitForm = () => {

        if(submitButton.value){
          submitButton.value.setAttribute("data-kt-indicator", "on");
        }

        store.dispatch(Actions.ADD_CONSUMER,formData).then(() => {

          success.value = true;
          errors.value = [];
          submitButton.value?.removeAttribute("data-kt-indicator");
          window.scrollTo(0,0);
          setTimeout(function(){
            router.push('/consumers/list')
          },1000)

        }).catch((response) => {
          setTimeout(function(){
            const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
            input.focus();
          },100)

          errors.value = response.data.errors;

          submitButton.value?.removeAttribute("data-kt-indicator");

        })
      }

      return {
        submitButton,
        formData,
        submitForm,
        errors,
        success
      }
    }
  }
</script>
